<template>
    <div id="not-authenticated" :class="{ 'without-branding': without_branding }">
        <app-vertical-lines v-if="vertical_lines_enabled" />

        <router-view id="app-body" :without-branding="without_branding"></router-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appVerticalLines from '@/components/app-vertical-lines'

export default {
    components: {
        appVerticalLines,
    },

    data() {
        return {}
    },

    computed: {
        ...mapGetters([
            'theme',

            'vertical_lines_enabled',
        ]),

        without_branding() {
            const without_branding = this.theme == 'vd' && !(this.$route.query.branding && this.$route.query.branding == 'true')

            return without_branding
        }
    },
}
</script>

<style lang="scss">
#not-authenticated {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    min-height: 100%;
    padding: 24px;
    background-image: $layout-not-authenticated-background-image;

     #app-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        height: 100%;
        z-index: 1;

        .app-branding-logo {
            margin-bottom: 80px;
        }

        h2 {
            margin-bottom: 40px;
            text-align: center;
        }

        form,
        .form {
            border-radius: $border-radius-secondary;
            box-shadow: var(--box-shadow-tertiary);
        }

        form,
        .form,
        .wrapper {
            position: relative;

            width: 100%;
            max-width: 610px;

            padding: 80px;
            background: var(--color-component-bg-primary);

            p,
            .app-input {
                margin-bottom: 30px;
            }

            p {
                font-size: 18px;

                text-align: center;
            }

            .actions {
                display: flex;
                flex-direction: column;
                align-items: center;

                button {
                    width: 100%;
                    max-width: 210px;
                }

                a {
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--color-link);
                    text-decoration: underline;
                    transition: color $transition-duration-primary;

                    &:hover {
                        color: var(--color-link-hover);
                    }
                }

                button + a,
                button + button {
                    margin-top: 22px;
                }

                p {
                    margin-top: 30px;
                    margin-bottom: 15px;
                }
            }
        }
    }

    &.without-branding {
        --color-sign-in-gradient-from: #333;
        --color-sign-in-gradient-to: #333;

        --box-shadow-tertiary: 0 2px 8px 0 rgba(51, 51, 51, 0.2);

        --color-component-bg-primary: #fff;

        --color-link: #000;
        --color-link-hover: #f00;

        --color-text-default: #000;
        --color-text-primary: #000;

        --color-input-bg: #fff;
        --color-input-border: #5f6369;
        --color-input-border-hover: #5f6369;
        --color-input-border-active: #5f6369;
        --color-input-border-error: #f00;
        --color-input-placeholder: #5f6369;
        --color-input-label: #000;
        --color-input-label-typing: #5f6369;

        --color-btn-primary: #fff;
        --color-btn-primary-hover: #fff;
        --color-btn-primary-active: #fff;
        --color-btn-primary-bg: #f00;
        --color-btn-primary-bg-hover: #900;
        --color-btn-primary-bg-active: #900;
        --color-btn-primary-disabled-bg: rgba(255, 0, 0, 0.4);

        // font-family: 'Helvetica', sans-serif;
    }
}

@media (max-width: $mobile-size) {
    #not-authenticated {
        padding: 24px 15px;

        #app-body {
            form,
            .form,
            .wrapper { 
                padding: 25px 15px 50px;
            }
        }
    }
}
</style>
